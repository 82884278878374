import * as React from "react";
import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import useWindowSize from "../../helpers/useWindowSize";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { fireGAEvent } from "../../helpers/ga";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";

// install Swiper modules
SwiperCore.use([Navigation]);

const swiperSettingsDesktop = {
  slidesPerView: 4.4,
  speed: 300,
  spaceBetween: 16,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  navigation: {
    nextEl: ".carousel-arrow-next",
    prevEl: ".carousel-arrow-prev",
  },
};

const swiperSettingsTablet = {
  slidesPerView: 1.4,
  speed: 300,
  spaceBetween: 16,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  navigation: {
    nextEl: ".carousel-arrow-next",
    prevEl: ".carousel-arrow-prev",
  },
};

const swiperSettingsMobile = {
  slidesPerView: 1.8,
  speed: 300,
  spaceBetween: 16,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  navigation: {
    nextEl: ".carousel-arrow-next",
    prevEl: ".carousel-arrow-prev",
  },
};

const SwiperContainer = styled(Swiper)`
  .swiper-slide {
    width: 182px;
    text-align: center;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    height: unset;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide.active {
    border-color: var(--secondary-color);
  }

  .swiper-slide.active .consumption {
    background: #fdecec;
    color: #e6401e;
  }
`;
const NoSwiperContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 40px;

  .swiper-slide {
    width: 182px;
    text-align: center;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
  }

  .swiper-slide.active {
    background: var(--secondary-color);
    color: white;
  }

  .swiper-slide.active .consumption {
    background: #d33818;
    color: white;
  }
`;
const CardBox = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding-top: 17px;
`;

const CardContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const CardHeadContainer = styled.div`
  padding: 0 10px;
`;

const CardHead = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  p {
    margin: 0;
    font-size: 14px;
    // min-height: 3.72em;
  }
`;

const CardImg = styled.div`
  img {
    margin: 0;
  }
`;

const CardBody = styled.div`
  align-self: end;

  .consumption {
    margin: 0;
    padding: 10px 0;
    font-size: 14px;

    background: #f3f3f3;
    color: var(--secondary-color);
  }
`;

const CarouselHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BusinessCardsCarousel = ({ initialData, updateConsumptionValue, isCalculatorTouched }) => {
  const [windowWidth] = useWindowSize();
  const previouslySelectedBusinessCardIndex = new URLSearchParams(
    window.location.search
  ).get("businesscard");
  const [selectedCardIndex, setSelectedCardIndex] = useState(
    previouslySelectedBusinessCardIndex
      ? Number(previouslySelectedBusinessCardIndex)
      : -1
  );
  const offers = useStaticQuery(graphql`
    {
      allContentfulLayoutBusinessCardsBusinessCard(
        filter: { node_locale: { eq: "bg" } }
      ) {
        nodes {
          title
          size
          consumption
          icon {
            file {
              url
            }
          }
          iconActive {
            file {
              url
            }
          }
          background
        }
      }
    }
  `);
  const [items] = useState(
    offers.allContentfulLayoutBusinessCardsBusinessCard.nodes
  );

  const isDesktop = windowWidth >= 1200;
  const isTablet = windowWidth < 1200 && windowWidth >= 768;
  const isMobile = windowWidth < 768;

  const swiperRef = useRef(null);
  const [swiperReady, setSwiperReady] = useState(false);

  useEffect(() => {
    if (swiperReady && swiperRef.current) {
      swiperRef.current.swiper.on("slideChange", setEqualSlideHeight);
    }
  }, [swiperReady]);

  const setEqualSlideHeight = () => {
    const slides = document.querySelectorAll(".swiper-slide");
    let maxHeight = 0;

    slides.forEach((slide) => {
      slide.style.height = "auto";
    });

    slides.forEach((slide) => {
      maxHeight = Math.max(maxHeight, slide.offsetHeight);
    });

    slides.forEach((slide) => {
      slide.style.height = `${maxHeight}px`;
    });
  };

  const changeBusiness = (e, title, consumption, cardIndex) => {
    setSelectedCardIndex(cardIndex);
    updateConsumptionValue(consumption);

    fireGAEvent("example_business_type_clicked", { bussiness_type: title });
  };

  const list = useMemo(() => {
    return items.map((card, index) => {
      const isCardSelected = index === selectedCardIndex;
      return (
        <SwiperSlide
          className={isCardSelected ? "active" : ""}
          onClick={(e) =>
            changeBusiness(e, card?.title, card.consumption, index)
          }
          key={index}
        >
          <button
            type="submit"
            value={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "0",
              background: "transparent",
              border: "0",
            }}
          >
            <CardBox>
              <CardContainer>
                <CardHeadContainer>
                  <CardHead>
                    <CardImg>
                      <img src={card.icon?.file.url} alt="icon" />
                    </CardImg>
                    <p>{card?.title}</p>
                    <div className="small-text">{card?.size} кв.м</div>
                  </CardHead>
                </CardHeadContainer>
                <CardBody>
                  <p className="consumption" id={card?.consumption}>
                    {card?.consumption} кВтч. / мес.
                  </p>
                </CardBody>
              </CardContainer>
            </CardBox>
          </button>
        </SwiperSlide>
      );
    });
  }, [items, selectedCardIndex]);

  return items.length > 0 ? (
    <div className="col-md-12" style={{ padding: "0" }}>
      <CarouselHead>
        <p>Изберете от примерни бизнеси</p>
        <div style={{ paddingRight: "15px", minWidth: "61px" }}>
          <ArrowBackIosNewIcon
            sx={{ width: "15px", height: "15px" }}
            className="carousel-arrow-prev"
          />
          <ArrowForwardIosIcon
            sx={{ width: "15px", height: "15px" }}
            className="carousel-arrow-next"
          />
        </div>
      </CarouselHead>

      {isDesktop && (
        <SwiperContainer
          style={{ paddingBottom: '5px', paddingRight: '2.85rem'}}
          initialSlide={selectedCardIndex}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper.el;
            setSwiperReady(true);
          }}
          {...swiperSettingsDesktop}
          slidesPerView={isCalculatorTouched ? 2.1 : swiperSettingsDesktop.slidesPerView}
        >
          {list}
        </SwiperContainer>
      )}
      {isTablet && (
        <SwiperContainer
          initialSlide={selectedCardIndex}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper.el;
            setSwiperReady(true);
          }}
          {...swiperSettingsTablet}
        >
          {list}
        </SwiperContainer>
      )}
      {isMobile && (
        <SwiperContainer
          initialSlide={selectedCardIndex}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper.el;
            setSwiperReady(true);
          }}
          {...swiperSettingsMobile}
        >
          {list}
        </SwiperContainer>
      )}
    </div>
  ) : null;
};

export default BusinessCardsCarousel;
