import React from 'react';

import './loaders.css';

export const CircularLoader = () => {
  return (
    <div className="loader">
      <div className="loaderFace"></div>
      <ul className="bars">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default CircularLoader;
