import React from "react";
import styled from "styled-components";

import Bolt from "../../atoms/icons/Bolt";
import BoltSelected from "../../atoms/icons/BoltSelected";
import Wallet from "../../atoms/icons/Wallet";
import HelpCenter from "../../atoms/icons/HelpCenter";
import HelpCenterSelected from "../../atoms/icons/HelpCenterSelected";
import WalletSelected from "../../atoms/icons/WalletSelected";

const CalcOptionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 -2rem 16px -2rem;
    width: calc(100% + 4rem);
`;

const CalcOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 7px;
  cursor: pointer;

  /* Apply different styling when selected */
  border-bottom: ${({selected}) => selected ? '2px solid #E3422A' : '1px solid #d1d1d1'};
  color: ${({selected}) => selected ? '#E6401E' : '#848484'}
`;

export const CalcOptions = ({selectedOption, changeSelectedOption}) => {
  return (
    <CalcOptionContainer>
      <CalcOption selected={selectedOption === 0} onClick={() => changeSelectedOption(0)}>
        {selectedOption === 0 ? <BoltSelected /> : <Bolt />}
        кВтч.
      </CalcOption>
      <CalcOption selected={selectedOption === 1} onClick={() => changeSelectedOption(1)}>
        {selectedOption === 1 ? <WalletSelected /> : <Wallet />}
        лв.
      </CalcOption>
      <CalcOption selected={selectedOption === 2} onClick={() => changeSelectedOption(2)}>
        {selectedOption === 2 ? <HelpCenterSelected /> : <HelpCenter />}
        Не знам
      </CalcOption>
    </CalcOptionContainer>
  );
};

export default CalcOptions;
