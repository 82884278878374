import React from "react"
import PropTypes from "prop-types"

import "./RadioInput.css"

const RadioInput = ({ label, id, ...props }) => {
  return (
    <label className="radio-input" htmlFor={id}>
      {label}
      <input id={id} type="radio" {...props} />
      <span className="checkmark"></span>
    </label>
  )
}

export default RadioInput

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

RadioInput.defaultProps = {
  type: "radio",
}
