import React, { useCallback, useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import OperatorsBase from "../../atoms/select/OperatorsBase";
import RadioInput from "../../atoms/inputs/RadioInput";
import CalcOptions from "../../atoms/select/CalcOptions";
import BusinessCardsCarousel from "../../Carousel/BusinessCardsCarousel";

import { MONEY_TO_KW_MULTIPLIER } from "../../../helpers/constants";
import roundNumber from "../../../helpers/roundNumber";
import colors from "../../../styled/colors";
import { CALCULATOR_CONSUMATION_TEXT } from "../../../helpers/constants";
import CircularLoader from "../../atoms/loaders/CircularLoader";

const BLANK_CONSUMPTION_LV = "";

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
  { index: 1 }
);

const BoxStyle = styled(Box)`
  margin-bottom: 2rem;
  box-shadow: 0px 1px 3px #00000066 !important;
`;
const BoxContainer = styled.div`
  padding: 2rem;
  font-weight: 600;
`;

const OutlinedInputStyle = styled(OutlinedInput)`
  background-color: white;

  .MuiOutlinedInput-input {
    font-weight: 600;
  }
`;

const ItemLink = styled.a`
  align-items: start;
  margin-bottom: 30px;

  :hover {
    border-bottom: none;
  }
`;

const Hint = styled.p`
  color: #e6401e;
`;

const Form = styled("form")`
  position: ${({ sticky }) => (sticky ? "sticky" : "static")};
  top: ${({ sticky }) => (sticky ? "1rem" : "0")};
`;

const ElectricityMeterForm = ({
  initialData,
  onFormSubmit,
  isCalculatorTouched,
  startOfferLoading,
  isLoading,
  location,
  toggleConsumptionDisplay = () => {},
  setIsCalculatorTouched = () => {},
  sticky = false,
}) => {
  const classes = useStyles();

  const initialState = {
    consumption: "",
    consumptionlv: "",
    meter: "e",
    level: "l",
    day: "",
    night: "",
    instPower: "10",
    operator: "",
    showMoreSection: false,
    ...initialData,
  };

  const [state, setState] = useState(initialState);
  const [cachedState, setCachedState] = useState(initialState);
  const [firstChanged, setFirstChanged] = useState("");
  const preloadedSelectedOptionId = Number(
    new URLSearchParams(location.search).get("option")
  );
  const [selectedOption, setSelectedOption] = useState(
    preloadedSelectedOptionId || 0
  );

  const updateConsumptionValue = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        consumption: roundNumber(value),
        option: selectedOption,
      }));
    },
    [state]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "consumption": {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          consumptionlv: BLANK_CONSUMPTION_LV,
          day: value * 0.6,
          night: value * 0.4,
        }));

        break;
      }

      case "consumptionlv": {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          consumption: roundNumber(value * MONEY_TO_KW_MULTIPLIER),
        }));

        break;
      }

      case "night":
      case "day": {
        setState((prevState) => {
          const { consumption } = prevState;

          const dayAndNightArr = ["day", "night"];
          const otherConsumption = dayAndNightArr.filter((x) => x !== name)[0];

          if (
            (firstChanged && firstChanged !== name) ||
            Number(value) > Number(consumption)
          ) {
            return {
              ...prevState,
              [name]: value,
              consumption: Number(value) + Number(prevState[otherConsumption]),
            };
          }

          const updatedState = {
            ...prevState,
            [name]: value,
            [otherConsumption]: Number(consumption) - Number(value),
          };

          return {
            ...updatedState,
            consumptionlv: BLANK_CONSUMPTION_LV,
          };
        });

        break;
      }

      // case "operator": {
      //   setState((prevState) => ({
      //     ...prevState,
      //     operatorLabel: ,
      //   }));

      //   break;
      // }

      default: {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        break;
      }
    }
  };

  const setLastChangedOnBlur = (e) => {
    const { name, value } = e.target;

    if (firstChanged) {
      return;
    }

    if (Number(cachedState[name]) !== Number(value)) {
      setCachedState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setFirstChanged(name);
    }
  };

  // const calculatorSwitch = (e) => {
  //   e.preventDefault();
  //   const boxContainers = document.getElementsByClassName("switch-box");
  //   for (let index = 0; index < boxContainers.length; index++) {
  //     const box = boxContainers[index];
  //     box.style.display = box.style.display === "none" ? "block" : "none";
  //   }

  //   if (e.target.tagName === "BUTTON") {
  //     var button = document.querySelector("button[type='submit']");
  //     button.click();
  //   }
  // };


  useEffect(() => {
    const opt = Number(new URLSearchParams(window.location.search).get("option")) || 0
    setSelectedOption(opt);
  }, [isCalculatorTouched])

  const changeSelectedOption = (optionId) => {
    setSelectedOption(optionId);
    const search = new URLSearchParams(window.location.search)
    search.set('option', optionId)
    const qs = search.toString()
    window.history.pushState({ path: {}}, "", `?${qs}`);
    toggleConsumptionDisplay(optionId === 2);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    startOfferLoading();
    const { night, level, instPower, day, showMoreSection, ...rest } = state
    const search = new URLSearchParams(location.search)
    var searchParams = new URLSearchParams({...search, ...rest, option: selectedOption });
    if(e.nativeEvent.submitter?.value) {
      searchParams.set("businesscard", e.nativeEvent.submitter?.value);
    }
    onFormSubmit(searchParams);
    if(state.consumption > 0) setIsCalculatorTouched(true);
  };

  // const handleShowMore = (e => {
  //   e.preventDefault()
  //   setState(prevState => ({
  //     ...prevState,
  //     showMoreSection: true,
  //   }))
  // }

  return (
    <Form id="compare" onSubmit={onSubmit} sticky={sticky}>
      <BoxStyle
        sx={{
          borderTop: 5,
          borderColor: colors.secondary,
          boxShadow: 1,
          borderRadius: 1,
          bgcolor: "background.paper",
        }}>
        <BoxContainer className="switch-box">
          <h4 className="heading-4 text-uppercase element--spacing">
            КАЛКУЛАТОР НА ОФЕРТИ ЗА ТОК
          </h4>

          <Hint>
            Въведете колко електричество консумирате или плащате на месец:
          </Hint>

          <CalcOptions
            selectedOption={selectedOption}
            changeSelectedOption={changeSelectedOption}
          />
          {selectedOption === 0 && (
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}>
              <InputLabel htmlFor="consumption">Консумация на ток</InputLabel>
              <OutlinedInput
                fullWidth
                name="consumption"
                id="consumption"
                value={state.consumption > 0 ? state.consumption : ""}
                onChange={handleChange}
                onBlur={(e) => {
                  var form = document.querySelector("form#compare");
                  form.dispatchEvent(new Event("submit", { bubbles: true }));
                }}
                type="number"
                endAdornment={
                  <InputAdornment position="end">кВтч./мес.</InputAdornment>
                }
                label="Консумация на ток"
                placeholder="Консумация на ток"
                sx={{
                  "input::-webkit-outer-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </FormControl>
          )}
          {selectedOption === 1 && (
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}>
              <InputLabel htmlFor="consumptionlv">Сметка за ток</InputLabel>
              <OutlinedInputStyle
                fullWidth
                name="consumptionlv"
                id="consumptionlv"
                value={state.consumptionlv > 0 ? state.consumptionlv : ""}
                onChange={handleChange}
                type="number"
                endAdornment={
                  <InputAdornment position="end">лв./мес.</InputAdornment>
                }
                label="Сметка за ток"
                placeholder="Сметка за ток"
                sx={{
                  "input::-webkit-outer-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </FormControl>
          )}
          {selectedOption === 2 && (
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
              style={{
                display: selectedOption === 2 ? "" : "none",
                marginRight: "-2rem",
                width: "calc(100% + 2rem)",
              }}>
              <BusinessCardsCarousel
                isCalculatorTouched={isCalculatorTouched}
                updateConsumptionValue={updateConsumptionValue}
              />
            </FormControl>
          )}
          <OperatorsBase
            defaultValue={state.operator}
            onChange={handleChange}
          />
          {/* <Box
            sx={{
              py: 4,
            }}
          >
            <h5 className="heading-5">Тип електромер</h5>
            <div>
              <RadioInput
                id="typeE"
                name="meter"
                value="e"
                label="Еднотарифен"
                onChange={handleChange}
                checked={state.meter === "e"}
              />
              <FormHelperText></FormHelperText>
            </div>
            <div>
              <RadioInput
                id="typeM"
                name="meter"
                value="m"
                label="Двутарифен дневна/нощна"
                onChange={handleChange}
                checked={state.meter === "m"}
              />
              <FormHelperText></FormHelperText>
            </div>
            <div className="row">
              <div className="col-md-12">
                {state.meter === "m" ? (
                  <div id="dayNight" className="row">
                    <div className="col-md-12">
                      <FormControl>
                        <InputLabel htmlFor="day">ДНЕВНА</InputLabel>
                        <OutlinedInput
                          disabled={state.meter !== "m"}
                          name="day"
                          id="day"
                          value={state.day > 0 ? state.day : ""}
                          onChange={handleChange}
                          onBlur={setLastChangedOnBlur}
                          endAdornment={
                            <InputAdornment position="end">
                              кВтч.
                            </InputAdornment>
                          }
                          type="number"
                          label="ДНЕВНА"
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-12">
                      <FormControl>
                        <InputLabel htmlFor="night">НОЩНА</InputLabel>
                        <OutlinedInput
                          disabled={state.meter !== "m"}
                          name="night"
                          id="night"
                          value={state.night > 0 ? state.night : ""}
                          onChange={handleChange}
                          onBlur={setLastChangedOnBlur}
                          endAdornment={
                            <InputAdornment position="end">
                              кВтч.
                            </InputAdornment>
                          }
                          type="number"
                          label="НОЩНА"
                        />
                      </FormControl>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Box> */}
          <Button
            style={{
              backgroundColor: "#E6401E",
            }}
            type="submit"
            fullWidth>
            {isCalculatorTouched ? "Актуализирай" : "Намери"}
            {isLoading && <CircularLoader />}
          </Button>
        </BoxContainer>
      </BoxStyle>
    </Form>
  );
};

export default ElectricityMeterForm;
