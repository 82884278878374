import React, { useState } from "react";

export const useLoaderState = () => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    setIsLoading(true);
  };

  const endLoading = () => {
    setIsLoading(false);
  };

  return [isLoading, startLoading, endLoading];
};

export default useLoaderState;
