import { styled } from "@mui/material"
import React from "react"
import SvgLogoIcon from "../../../images/wallet-selected.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const WalletSelected = ({ width = "18", height = "18", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} {...props} />
    </>
  )
}

export default WalletSelected
