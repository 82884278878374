import { styled } from "@mui/material"
import React from "react"
import SvgLogoIcon from "../../../images/bolt.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const Bolt = ({ width = "13", height = "15", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} {...props} />
    </>
  )
}

export default Bolt
