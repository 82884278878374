import { styled } from "@mui/material"
import React from "react"
import SvgLogoIcon from "../../../images/warning.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const Warning = ({ width = "20", height = "19", minWidth="20", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} sx={{ minWidth }} {...props} />
    </>
  )
}

export default Warning
