import React from "react";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";

const UlStyle = styled.ul`
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 16px;
  line-height: 19.5px;
`;

export const UnorderedListTypeSecond = ({ props }) => {    
  return (
    <UlStyle>
      {props.length &&
        props.map((item) => {
          return (
            <ListItem key={item}>
              <CheckIcon color="secondary" style={{ marginLeft: 0 }} /> {item}
            </ListItem>
          );
        })}
    </UlStyle>
  );
};

export default UnorderedListTypeSecond;
