import { styled } from "@mui/material"
import React from "react"
import SvgLogoIcon from "../../../images/help-center-selected.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const HelpCenterSelected = ({ width = "14", height = "14", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} {...props} />
    </>
  )
}

export default HelpCenterSelected
