import React, { useCallback, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import OfferListItem from "./OfferListItem";

import "./OfferList.css";

import { operatorsOptions } from "../../atoms/select/OperatorsBase";
import Warning from "../../atoms/icons/Warning";

const InfoValue = styled.div`
  font-weight: 500;
`;

const Link = styled.a`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: #1c1b1f;
  border: 0;
  text-decoration: underline;
`;

const WarningStyle = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  padding: 10px 13px;
  color: #e6401e;
  background-color: #fad9d4;
  font-size: 16px;
  line-height: 21px;
`;

const jumpAnimation = keyframes`
  from {
    transform: translate(0rem, 1rem);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const OffersList = styled.section`
  animation: ${({ isLoading }) =>
    isLoading
      ? css`
          ${jumpAnimation} 0.5s forwards
        `
      : "none"};
`;

const OfferList = ({
  offers,
  consumptionData,
  operator,
  sortingFn,
  consumption,
  endOffersLoading,
  isLoading,
  search
}) => {
  const renderOffers = useCallback(() => {
    let offersToRender = [...offers];

    if (sortingFn) {
      offersToRender = offersToRender.sort(sortingFn);
    }

    return offersToRender.map((offer) => {
      return (
        <OfferListItem
          key={offer.id}
          offer={offer}
          consumptionData={consumptionData}
          operator={operator}
          consumption={consumption}
        />
      );
    });
  }, [sortingFn, offers, operator, consumptionData, consumption]);

  const currentOperator = operatorsOptions.find(
    (operator) => operator.value === consumptionData.operator
  )?.label;

  useEffect(() => {
    setTimeout(() => {
      endOffersLoading();
    }, 500);
  }, [offers]);

  const isExampleData = search.businesscard || !search.consumption

  return (
    <OffersList isLoading={isLoading} className="offers vertical--spacing">
      {isExampleData ? (<h2>Примерни оферти</h2>) : (<h2>Вашите оферти</h2>)}
      {isExampleData && (
        <div className="offers__info-container">
          <div className="offers__row">
            <div className="offers__info">
              Консумация:
              <InfoValue>{consumptionData.consumption}кВтч./мес.</InfoValue>
            </div>
            <div className="offers__info">
              Мрежов оператор: <InfoValue>{currentOperator}</InfoValue>
            </div>
          </div>
          <div className="offers__row">
            <div className="offers__info">
              Инсталирана мощност:
              <InfoValue>{consumptionData.instPower}кВт.</InfoValue>
            </div>
            <div className="offers__info">
              Без включени{" "}
              <Link href="/kompensacii-za-visokite-ceni-na-elektroenergiyata">
                компенсации
              </Link>
            </div>
          </div>
        </div>
      )}
      {search.businesscard && (
        <WarningStyle>
          <Warning /> За персонализирани оферти въведете собствени данни в
          калкулатора
        </WarningStyle>
      )}
      <div className="offers-list">{renderOffers()}</div>
    </OffersList>
  );
};

export default OfferList;
