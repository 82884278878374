import { isEmpty } from "lodash"
import React from "react"
import styled from "styled-components"
import Box from "@mui/material/Box"
import {
  paths,
  STOCK_PRICE_POPUP_HEADING,
  STOCK_PRICE_POPUP_BODY,
} from "../../../helpers/constants"
import roundNumber from "../../../helpers/roundNumber"
import PriceUpDownIcon from "../../../images/icon-price-up-down.png"
import OfferCondition from "../../organisms/offers/OfferCondition"
import AlertModal from "../../organisms/alert-modal/AlertModal"
import Image from "../../atoms/image/Image"

import "./OfferListItem.css"
import { Link } from "gatsby"
import { isOfferPibex } from "../../../helpers/pibex"

import ExclusiveOfferBandSVG from "../../../images/exclusiveOffer.inline.svg";
import OfferDistributor from "./containers/offer-distributor/OfferDistributor"
import { fireGAEvent } from "../../../helpers/ga"

const StyledExclusiveOfferBand = styled(ExclusiveOfferBandSVG)`
  position: absolute;
  z-index: 2;
  right: -15px;
  top: -9px;
`;

const BandWrapper = styled(Box)`
  position: relative;
`;

const BoxStyle = styled(Box)`
  margin-bottom: 2rem;
  box-shadow: 0px 1px 3px #00000066 !important;
  overflow: hidden;
`
const StockPriceContainer = styled.div``

const StockAlertModal = styled(AlertModal)`
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 999;
  margin: 0 -33px -33px;
  width: calc(250%);
  font-size: 14px;
`

const BoxContainer = styled.div`

  @media (min-width: 768px) {
    padding: 2rem;
  }

  h3, .button {
    margin: 1rem;
    @media (min-width: 768px) {
     margin-right: auto;
     margin-left: auto;
  }
`

const OfferLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 1rem;

  @media (min-width: 768px) {
    margin-right: 0;
    margin-left: 0;

    &:after {
      content: "";
      position: absolute;
      top: -50%;
      right: 1rem;
      width: 2px;
      height: 1000px;
      background-color: #f3f3f3;
    }
  }
`

const PriceContainer = styled.div`
  display: flex;
  // align-items: flex-end;
  // flex-direction: row;
  // justify-content: space-between;
  align-items: inherit;
  flex-direction: column;
  gap: 10px;

  
  @media (min-width: 768px) {
    align-items: inherit;
    flex-direction: column;
  }
`

const Price = styled.div` 
  display: flex;
  align-items: flex-end;
  line-height: 1;

  &.highlighted {
    color: #e6401e;
  }

  .heading-2 {
    margin: 0 5px 0 0;

    + div {
      line-height: 1.2;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
`

const StockPrice = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: #e6401e;
  background-color: rgba(230, 64, 30, 0.2);
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  padding: 0.1rem;

  @media (min-width: 768px) {
    width: calc(100% - 1rem);
  }

  @media (max-width: 1024px) {
    width: 8rem;
  }

  svg {
    margin-right: 0.2rem;
  }
`

const GreenEnergy = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  height: 24px;
  background-color: #89ba2a;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  z-index: 1;
`

const constructLink = (path, paramsObj) => {
  if (paramsObj && !isEmpty(paramsObj)) {
    const params = Object.keys(paramsObj).filter((k) => Boolean(paramsObj[k]))
      .map(p => `${p}=${paramsObj[p]}`)
      .join('&');

    return `${path}?${params}`;
  }

  return path;
}

const OfferListItem = ({ offer, greenEnergy, operator, consumption }) => {
  const [stockPopupIsOpen, setStockPopupIsOpen] = React.useState(false)
  const handleStockModalVisibility = open => {
    setStockPopupIsOpen(open)
  }
  
  const isPibex = isOfferPibex(offer);
  const hasGreenEnergy = Boolean(offer?.greenEnergy);
  const isSpecialOffer = Boolean(offer?.specialOffer);

  /* TODO: link the buttons to the corresponding page */
  const renderOfferButton = (price, offerName, partner) => {
    return offer.details.type !== "dpi" && offer.details.type !== "standard" ? (
      <Link
        to={constructLink(
          `${paths.distributors.link}${offer.distributor.slug}/${offer.slug}`,
          {
            operator,
            consumption,
          }
        )}
        className="button button--secondary__invert button--sm"
        onClick={() =>
          fireGAEvent("offer_details_btn_clicked", {
            price: price,
            offerName: offerName,
            partner: partner,
          })
        }
      >
        Повече за офертата
      </Link>
    ) : null
  }

  return (
    <BandWrapper>
      {isSpecialOffer && <StyledExclusiveOfferBand />}
      <BoxStyle
        sx={{
          borderTop: 5,
          borderColor: "#B5B5B5",
          boxShadow: 1,
          borderRadius: 1,
          bgcolor: "background.paper",
        }}
      >
        {hasGreenEnergy && <GreenEnergy>Зелена енергия</GreenEnergy>}
        <BoxContainer>
          <div className="row offer">
            <OfferLeftContent className="col-md-4">
              <PriceContainer>
                <Price className={`${isPibex && "highlighted"}`}>
                  <div className="heading-2">
                    {roundNumber(offer.totalPrice)}{" "}
                  </div>
                  <div>лв./ мес.</div>
                </Price>
  
                {isPibex && (
                  <>
                    <StockPriceContainer
                      onClick={() => setStockPopupIsOpen(true)}
                    >
                      <StockPrice>
                        {/* <StockIcon></StockIcon> */}
                        <Image src={PriceUpDownIcon} width={20} />
                        Борсова цена
                      </StockPrice>
                    </StockPriceContainer>
                    <StockAlertModal
                      heading={STOCK_PRICE_POPUP_HEADING}
                      content={STOCK_PRICE_POPUP_BODY}
                      isOpen={stockPopupIsOpen}
                      onAlertModal={handleStockModalVisibility}
                      arrow={false}
                      icon={null}
                    />
                  </>
                )}
              </PriceContainer>

              <OfferDistributor offer={offer} />
            </OfferLeftContent>
            <div className="col-md-8">
              <h3 style={{ marginBottom: "2rem", paddingRight: "2.5" }}>"{offer.offerName}"</h3>
              <OfferCondition conditions={offer.offerConditions} />
              {renderOfferButton(
                roundNumber(offer.totalPrice),
                offer.offerName,
                offer?.distributor.title
              )}
            </div>
          </div>
        </BoxContainer>
      </BoxStyle>
    </BandWrapper>
  )
}

export default OfferListItem
/* TODO: Render actual data in the list when ready 
  Неустойки при предсрочно прекратяване (early exit fee) 
  Гаранция на цената на електроенергията (price guarantee) 
  Срокове за плащане (payment terms) 
  Възможност за предсрочно прекратяване от доставчика 
  Други договорни тежести (обезпечения, предсрочна изискуемост на дълга при смяна на доставчика, др)
  Санкции, ако не се реализира смяната по вина на купувача (пр. Дълг) 
  Бонус за клиента - плащат старите задължения и после ти взимат дълга
  Прекратяване от Клиента
  Обеспечение при забавено плащане
  Прекратяване при предложение за нова цена
  Автоматично удължаване за нов срок
  Електронна фактура (хартиената е за допълнителна цена)
  Общи условия - промените влизат в сила при изтичане на срок, ако никой не реагира
  Неустойка при предсрочно прекратяване
  Неустойка при предложение за нова цена
  Предизвестие при прекратяване
  Комбиниран договор
*/
